import React, { memo } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, color, font } from '../../../utils/style';

const PlayBestLotteryWrap = styled('div')`
  margin-bottom: 30px;
  .title-block {
    position: relative;
    margin: 3.5rem 0 1rem;
  }
  .h1 {
    margin: 0 auto 40px;
    text-align: center;
  }
  @media (max-width: ${breakpoints.md}) {
    .title-block {
      margin: 2.5rem 0 1rem;
    }
  }  
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      margin-bottom: 0.7rem;
    }
  }
`;

const PlayBestLotteryAll = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const LotteryBlock = styled('div')`
  flex-basis: 14.5%;
  a, .a {
    display: block;
    position: relative;
    padding-top: 40px;
    text-align:center;
    height:100%;
    &:hover, &:active {
      text-decoration: none;
      .lottery-title {
        box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
  .img-wrap {
    width: 128px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -64px;
  }
  .gatsby-image-wrapper {
    border-radius: 100%;
  }
  .lottery-title {
    display: block;
    height: 100%;
    padding: 120px 30px 30px;
    border-radius: 60px 60px 30px 30px;
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to bottom, #e4f5fc, #c5f5f8);
    color: #32ac9e;
    font-size: 16px;
    font-weight: ${font.boldWeight};
    text-transform: uppercase;
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    &.red-lottery {
      background-image: linear-gradient(to bottom, #ffe6ec, #ffd3d4);
      color: ${color.red};
    }
    &.yellow-lottery {
      background-image: linear-gradient(to bottom, #ffeeec, #ffe8c2);
      color: ${color.mustard};
    }
  }
  @media (max-width: ${breakpoints.md}) {
    flex-basis: 29%;
    margin-bottom: 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    flex-basis: 48%;
    a, .a {
      padding-top: 30px;
    }
    .img-wrap {
      width: 104px;
      margin-left: -52px;
    }
    .lottery-title {
      padding: 95px 30px 20px;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    .lottery-title {
      padding: 95px 15px 20px;
    }
  }
  
`;

const PlayBestLottery = ({ title, subtitle, lottery_images }) => {



  return (
    <PlayBestLotteryWrap>
      <div className="title-block">
        <h2 className="h1 gradient a-center">
          <span>{title}</span>
        </h2>
      </div>
      {subtitle && <p className="subtitle">{subtitle}</p>}

      <PlayBestLotteryAll>
        {lottery_images.map(item => {
          const localFile = item.image.localFile || {};
          return (
            <LotteryBlock key={item.link + Math.random()*100}>

              {item.link ? (
                <a title={item.tex} href={item.link}>
                  {item.tex &&
                  <span className={"lottery-title " + item.color}>{item.tex}</span>
                  }
                  {localFile?.childImageSharp?.fluid &&
                  <div className="img-wrap">
                    <Img
                      fluid={localFile.childImageSharp.fluid}
                      alt={item.tex ? item.tex : 'Lottery'}
                      fadeIn={false}
                      loading='eager'
                    />
                  </div>
                  }


                </a>
              ) : (
                <div className="a">
                  {item.tex &&
                  <span className={"lottery-title " + item.color}>{item.tex}</span>
                  }
                  {localFile?.childImageSharp?.fluid &&
                  <div className="img-wrap">
                    <Img
                      fluid={localFile.childImageSharp.fluid}
                      alt={item.tex ? item.tex : 'Lottery'}
                      fadeIn={false}
                      loading='eager'
                    />
                  </div>
                  }
                </div>
              )}
            </LotteryBlock>
          );
        })}

      </PlayBestLotteryAll>
    </PlayBestLotteryWrap>
  );
};

PlayBestLottery.propTypes = {
  lottery_images: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      link: PropTypes.string,
      tex: PropTypes.string,
      image: PropTypes.shape({
        alt_text: PropTypes.string,
      }),
    })
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PlayBestLottery.defaultProps = {
  lottery_images: [],
  title: '',
  subtitle: '',
};

export default memo(PlayBestLottery);
